import React from "react"
import InnerPage from "../components/innerPage"
import InnerHeader from "../components/InnerHeader"
import Breadcrumb from "../components/breadcrumb"
import { graphql, Link } from "gatsby"
import { trimUrl } from "../general/constants"
import { Helmet } from "react-helmet"

const Blog = ({data}) => {
  const {edges} = data.allStrapiBlogPages;

  return (
    <InnerPage>
      <Helmet>
        <title>Blog</title>
        <meta name="Description" content="Restaurant Passion Blog"/>
      </Helmet>
      <InnerHeader>
        <Breadcrumb label="Blog"  />
      </InnerHeader>
      <section className="container">
        <div className="row py3 py140md">
          {
            edges && edges.map((item, index) => {
              const {title, image, date} = item.node;
              const postUrl = trimUrl(title);

              return(
                <div key={index} className="colMd4 mb6md mb3 textCenter">
                  <img src={image.childImageSharp.fixed.src} className="imgFluid mb1" alt={title} />
                  <p className="textUppercase mb1">{date}</p>
                  <Link to={`${postUrl}`} className="linkUnstyled textSecondaryDark textMd fontSecondary textUppercase blogTitle">
                    {title}
                  </Link>
                </div>
              )
            })
          }
        </div>
      </section>
    </InnerPage>
  )
}

export default Blog

export const pageQuery = graphql`
  query BlogPage {
     allStrapiBlogPages {
       edges {
        node {
          id
          title
          date(formatString: "DD MMMM YYYY")
          content
          image {
            childImageSharp {
              fixed(width: 350, height: 233, fit: FILL) {
                src
              }
            }
          }
        }
      }
    }
  }
`
